

@media only screen and (max-width: 1610px) {
  .tasks {
    float: none;
    margin: 0 auto;
    width: 85%;
    max-width: 1500px;
    margin-bottom: 25px;

    .priority {
      min-width: 82px;
    }
  }
  .issue-info {
    float: none;
    margin: 0 auto;
    width: 85%;
    max-width: 1500px;


  }
}
@media only screen and (max-width: 980px) {
  .tasks,
  .issue-info {
    width: 80%;
  }
  .task-manage.table {
    display: block;
    overflow-y: scroll;
    width: 100%;
    padding-left: 40px;
    border: 1px solid #c7c5c5;
    .t-row {
      display: block;
      width: 912px;
      margin-top: -1px;
      &:last-child {
        .t-cell {
          border-bottom: none;
        }
      }
      .position {
        position: absolute;
        min-width: 40px;
        margin-left: -40px;
        margin-top: 0px;
        background: #fff;
        border-bottom: none;
        border-left: none;
      }
      .t-cell:nth-child(2) {
        margin-left: 20px;
      }

    }
  }
}

@media only screen and (max-width: 820px) {
  .main-header {
    .container{
      nav.mobile-menu {
        display: none;
        position: absolute;
        top: 60px;
        background: #599ac6;
        padding: 10px 15px;
        z-index: 9999;
        width: 200px;
        a {
          margin: 0;
          display: block;
          text-align: center;
          padding: 10px 0;
          &:not(:last-child) {
            border-bottom: 1px solid rgb(133, 181, 214);
          }
        }
      }
      .button-mobile-menu {
        display: block;
        float: left;
        &.opened{
          width: 0px;
          transition: width .6s;
          &:before {
            top: 5px;
            transform: rotate(45deg);
            transition: all .6s;
          }
          &:after {
            top: 5px;
            transform: rotate(-225deg);
            transition: all .6s;
          }
        }
      }
    }
  }

  .tasks {

    .action-bar {
      margin: 10px 0 15px 0px;

      .add-delete {
        width: 263px;
        display: block;
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-header {
    .container {
      .admin-box {
        display: block;
        position: absolute;
        top: -165px;
        right: 20px;
        background: #599ac6;
        padding: 5px 10px;
        z-index: 9999;
        border-radius: 4px;
        box-shadow: 0 0 3px 3px #ccc;
        opacity: 0;
        transition: opacity .5s ease, top .6s ease .2s;
        &.opened {
          top: 65px;
          opacity: 1;
          transition: top .4s ease, opacity .4s ease .2s;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          border: 7px solid transparent;
          border-bottom-color: #599ac6;
          top: -13px;
          right: 85px;
        }
        div:first-child a {
          padding: 0 15px 0 0;
        }
        div {
          a {
            img {
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
  body {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 100%;
    left: 100%;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    transition: opacity .6s ease, bottom .6s ease .6s, left .6s ease .6s;
    z-index: 9999;
    &.opened {
      bottom: 0;
      left: 0;
      z-index: 9999;
      opacity: 1;
      transition: opacity .6s ease;
    }
  }

  .tasks, .issue-info {
    width: 96%;
  }

  .admin-box-mobile-link {
    display: block;
  }
  .aside-menu {
    left: -70px;
    z-index: 9999;
    top: 30px;
    &.opened {
      .button-aside-menu {
        transform: rotate(-225deg);
        transition: all .4s ease .3s;
        left: 10px;
        top: 10px;
      }
    }
    .button-aside-menu {
      display: inline-block;
      border: 3px solid transparent;
      font-weight: bold;
      padding: 4px;
      text-align: center;
      left: 73px;
      top: 40px;
      position: absolute;
      border-bottom-color: #599ac6;
      border-right-color: #599ac6;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
      transform: rotate(-45deg);
      transition: all .4s ease .3s;
    }
  }
}

@media only screen and (max-width: 680px) {
  .main-header {
    .container {
      padding: 0 10px;

      .admin-box {
        right: 14px;

        &:before {
          right: 20px;
        }
      }
    }
  }
  .issue-info {
    .issue-info-item{
      .item-body-left,
      .item-body-right,
      .item-body-full {
        width: 100%;
        padding-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .issue-info{
    .action-bar > * {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .tasks {

    .action-bar {

      .filter-form {
        margin-bottom: 15px;
      }
    }
  }
}