* {
  outline: none;
}

*:focus {
  outline: none;
}

img {
  vertical-align: top;
  border: 0;
  max-width: 100%;
  height: auto;
}

header,
footer,
article,
section,
hgroup,
nav,
figure,
main {
  display: block;
}

form,
fieldset,
button {
  margin: 0;
  padding: 0;
  border: none;
}

button {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
}

input {
  box-sizing: border-box;
  border-radius: 0;
  font: 18px/30px "Trebuchet MS", Arial, Verdana, sans-serif;
  font-weight: 400;
  -webkit-appearance: none;
}

input[type=text], input[type=tel], input[type=email], input[type=password] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 10px;
  border: 1px solid #c7c5c5;
  border-width: 0 0 1px;
  margin: 0 0 30px;
}

input[type=text].error, input[type=tel].error, input[type=email].error, input[type=password].error {
  border-bottom: 1px solid #ec1b35;
}

input[type="submit"] {
  height: 30px;
  border-radius: 3px;
  border: none;
  background: #5b9bc7;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  max-width: 226px;
  font: 18px/30px "Trebuchet MS", Arial, Verdana, sans-serif;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

input[type="submit"]:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input::-webkit-input-placeholder {
  color: #6c6f6c;
  opacity: 1;
}

input:-moz-placeholder {
  color: #6c6f6c;
  opacity: 1;
}

input::-moz-placeholder {
  color: #6c6f6c;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #6c6f6c;
  opacity: 1;
}

input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

input:focus:-moz-placeholder {
  opacity: 0.5;
}

input:focus::-moz-placeholder {
  opacity: 0.5;
}

input:focus:-ms-input-placeholder {
  opacity: 0.5;
}

textarea {
  font: 30px/1.1 "Trebuchet MS", Arial, Verdana, sans-serif;
}

textarea::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
  text-transform: uppercase;
}

textarea:-moz-placeholder {
  color: #fff;
  opacity: 1;
  text-transform: uppercase;
}

textarea::-moz-placeholder {
  color: #fff;
  opacity: 1;
  text-transform: uppercase;
}

textarea:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
  text-transform: uppercase;
}

textarea:focus {
  border: 1px solid #00adef;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

textarea:focus:-moz-placeholder {
  opacity: 0.5;
}

textarea:focus::-moz-placeholder {
  opacity: 0.5;
}

textarea:focus:-ms-input-placeholder {
  opacity: 0.5;
}

textarea.error {
  border-bottom: 1px solid #ec1b35;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  outline: none;
  border: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  font: 14px/1.4 "Trebuchet MS", Arial, Verdana, sans-serif;
  color: #6c6f6c;
  text-size-adjust: 100%;
  background: #efecec;
}

a {
  text-decoration: none;
  color: #6c6f6c;
}

a:hover {
  text-decoration: underline;
}

h2 {
  font-size: 13px;
  color: #5b9bc7;
}

.small-btn {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  box-shadow: 1px 1px 0 #599ac6;
  background: #efecec;
  display: inline-block;
  text-indent: -9999px;
  position: relative;
}

.small-btn.chat {
  background: #efecec url("../images/interface.png") no-repeat 50% 50%;
}

.small-btn.edit {
  background: #efecec url("../images/edit.png") no-repeat 50% 50%;
}

.small-btn:hover {
  top: -2px;
}

.small-btn[class^="icon-"]:before, .small-btn[class*=" icon-"]:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-indent: 0;
}

#main {
  padding: 0 0 0 75px;
}

#main:after {
  content: "";
  display: block;
  clear: both;
}

#header {
  background: #599ac6;
  color: #fff;
  font-size: 18px;
  padding: 0 20px;
  height: 60px;
}

#header .container {
  padding: 0 10px;
}

#header .admin-box {
  float: right;
  padding: 3px 0 0;
}

#header .admin-box div {
  display: inline-block;
}

#header .admin-box div a {
  display: inline-block;
  color: #fff;
}

#header .admin-box div a img {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
}

#header .admin-box div:first-child a {
  padding: 0 35px 0 0;
}

#header .admin-box div:first-child:after {
  content: '';
  background-image: url(../images/sprite.png);
  background-position: -65px 0px;
  width: 1px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.logo {
  float: left;
  margin: 10px 40px 0 0;
}

.logo img {
  width: 51px;
}

#main-navigation ul {
  margin: 20px 0 10px;
  padding: 0;
  list-style: none;
  display: inline-block;
  line-height: 1.2;
}

#main-navigation ul li {
  float: left;
  margin: 0 25px 0 0;
}

#main-navigation a {
  color: #fff;
}

#main-navigation a:not(:last-child) {
  margin: 0 25px 0 0;
}

.aside-menu {
  position: fixed;
  top: 61px;
  left: 0;
}

.aside-menu ul {
  padding: 27px 19px 20px;
  background: #fff;
  border: 1px solid #c7c5c5;
  margin: 0;
}

.aside-menu ul li {
  display: block;
  padding: 15px 0 15px;
  background: url("../images/aside-separator.png") no-repeat 50% 0;
}

.aside-menu ul li:first-child {
  padding-top: 0;
  background: none;
}

.aside-menu ul li:last-child {
  margin-top: 115px;
  padding: 0;
  background: none;
}

.aside-menu ul li a {
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
}

.aside-menu ul li .new-file {
  background: url("../images/new-file.png") no-repeat;
  width: 29px;
  height: 38px;
}

.aside-menu ul li .list {
  background: url("../images/list.png") no-repeat;
  width: 29px;
  height: 40px;
}

.aside-menu ul li .chat {
  background: url("../images/chat.png") no-repeat;
  width: 29px;
  height: 24px;
}

.aside-menu ul li .statistic {
  background: url("../images/bar-chart.png") no-repeat;
  width: 30px;
  height: 26px;
}

.aside-menu ul li .settings {
  background: url("../images/settings.png") no-repeat;
  width: 28px;
  height: 28px;
}

.admin-box-mobile-link {
  display: none;
  float: right;
}

.work-section {
  float: left;
  padding-top: 12px;
  width: 58.86%;
}

.work-section .action-bar {
  margin: 0 0 14px;
}

.work-section .action-bar:after {
  content: "";
  display: block;
  clear: both;
}

.work-section .action-bar .small-btn {
  margin: 0 0 0 10px;
}

.work-section .action-bar .refresh {
  float: left;
  margin: 0 7px 0 0;
}

.work-section .action-bar .refresh:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background: url("../images/refresh.png") 50% 50% no-repeat;
  position: relative;
  top: 0;
  left: 0;
  transform: rotate(0deg);
  transition: transform 3s;
}

.work-section .action-bar .refresh:hover:before {
  transform: rotate(360deg);
  transition: transform 3s;
}

.work-section .action-bar .settings {
  float: right;
  margin: 0 7px;
}

.work-section .action-bar .settings:before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background: url("../images/tools.png") 50% 50% no-repeat;
  position: absolute;
  transform: rotate(0deg);
  transition: transform 3s;
  top: 0px;
  left: 0px;
}

.work-section .action-bar .settings:hover:before {
  transform: rotate(360deg);
  transition: transform 3s;
}

.work-section .action-bar .filter-option {
  display: none;
}

.work-section table {
  width: 100%;
  background: #fff;
  border: 1px solid #c7c5c5;
  border-collapse: collapse;
}

.work-section table th {
  border: 1px solid #c7c5c5;
  height: 30px;
  background: #599ac6;
  text-align: center;
  padding: 0 10px;
  color: #fff;
}

.work-section table td {
  border: 1px solid #c7c5c5;
  height: 30px;
  padding: 0 10px;
}

.work-section table tr:nth-child(2n) td {
  background: #f5f5f5;
}

.work-section table tr:hover td {
  background: #eff3fc;
}

.work-section table tr.active td {
  background: #bfcef5;
}

.work-section table .field-id {
  text-align: center;
}

.work-section table .status {
  text-align: center;
}

span.processed {
  background: #3e9a5c;
  color: #fff;
  padding: 3px 5px 1px;
  vertical-align: middle;
  border-radius: 3px;
  font-size: 12px;
  display: inline-block;
}

span.not-processed {
  background: #ff6262;
  color: #fff;
  padding: 3px 5px 1px;
  vertical-align: middle;
  border-radius: 3px;
  font-size: 12px;
  display: inline-block;
}

span.active {
  background: #599ac6;
  color: #fff;
  padding: 3px 5px 1px;
  vertical-align: middle;
  border-radius: 3px;
  font-size: 12px;
  display: inline-block;
}

.select-field {
  position: relative;
}

.select-field:after {
  content: "";
  position: absolute;
  background: url("../images/drop-down.png") no-repeat;
  width: 11px;
  height: 6px;
  right: 9px;
  top: 50%;
  margin: -3px 0 0;
}

.select-field select {
  display: block;
  background: #efecec;
  width: 131px;
  height: 30px;
  border-radius: 4px;
  border: none;
  -webkit-appearance: none;
  padding: 0 10px;
  font-size: 13px;
  color: #6c6f6c;
  text-align: center;
  line-height: 30px;
  box-shadow: 1px 1px 0 #599ac6;
}

.info-section {
  float: right;
  width: 40.92%;
  border: 2px solid #c7c5c5;
  padding: 10px;
  background: #fff;
  margin-right: 2px;
  box-sizing: border-box;
}

.info-section:after {
  content: '';
  display: block;
  clear: both;
}

.info-section .info-section-headline {
  padding: 10px 0;
}

.info-section .info-section-headline:after {
  content: '';
  display: block;
  clear: both;
}

.info-section .info-section-headline h2 {
  overflow: hidden;
  font-size: 18px;
  color: #6c6f6c;
  margin: 0;
  line-height: 30px;
}

.info-section .action-bar {
  float: right;
}

.info-section .action-bar .select-field {
  float: left;
}

.info-section .action-bar .small-btn {
  margin: 0 0 0 10px;
}

.info-section .issue-info-item {
  margin-bottom: 10px;
}

.info-section .issue-info-item .item-header {
  background: linear-gradient(to right, #e7e9e8, 12%, #fff);
  cursor: pointer;
  font-size: 13px;
  line-height: 30px;
  padding: 0 10px;
}

.info-section .issue-info-item .item-header h3 {
  margin: 0 0 15px;
}

.info-section .issue-info-item .item-header h3:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin: -2px 7px 0 0;
  width: 11px;
  height: 11px;
  background: url("../images/ico-minus.png") no-repeat 0 50%;
}

.info-section .issue-info-item .item-body {
  padding: 0 27px 5px;
  font-size: 13px;
  transition: height 0.5s linear;
}

.info-section .issue-info-item .item-body .details-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.info-section .issue-info-item .item-body .details-list li {
  overflow: hidden;
  margin: 0 0 15px;
  border-bottom: 1px solid #e1e1e1;
}

.info-section .issue-info-item .item-body .details-list .item-label {
  float: left;
  width: 115px;
  margin: 0 10px 0 0;
}

.info-section .issue-info-item .item-body .details-list .item-description {
  overflow: hidden;
}

.info-section .issue-info-item .item-body .details-list .item-description p {
  margin: 0 0 15px;
}

.info-section .issue-info-item .item-body .details-list .item-description .edit:before,
.info-section .issue-info-item .item-body .details-list .item-description .save:before,
.info-section .issue-info-item .item-body .details-list .item-description .cancel:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-indent: 0;
  color: #599ac6;
  vertical-align: middle;
  margin: 0 4px 0 0;
}

.info-section .issue-info-item .item-body .details-list .item-description .edit {
  width: 15px;
  height: 15px;
  background-size: cover;
  box-shadow: none;
  top: 0 !important;
  background: none;
  color: #599ac6;
  vertical-align: middle;
  margin: 0 0 0 5px;
}

.info-section .issue-info-item .item-body .details-list .item-description .edit:before {
  content: "\e906";
  position: absolute;
  left: 0;
  top: 0;
}

.info-section .issue-info-item .item-body .details-list .item-description .save {
  margin: 0 10px 0 0;
  font-size: 12px;
}

.info-section .issue-info-item .item-body .details-list .item-description .save:before {
  content: "\ea10";
  font-size: 13px;
}

.info-section .issue-info-item .item-body .details-list .item-description .cancel {
  font-size: 12px;
}

.info-section .issue-info-item .item-body .details-list .item-description .cancel:before {
  content: "\ea0f";
  font-size: 11px;
}

.info-section .issue-info-item .item-body .details-list .item-description input {
  margin: 0 0 10px;
}

.info-section .issue-info-item .item-body .two-columns {
  column-count: 2;
  column-gap: 50px;
  padding: 0 0 10px;
}

.info-section .issue-info-item .item-body ol {
  margin: 0 0 10px 17px;
  padding: 0;
}

.info-section .issue-info-item .item-body ol li {
  margin: 0 0 5px;
}

.info-section .issue-info-item .item-body .changes-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.info-section .issue-info-item .item-body .changes-list li {
  margin: 0 0 15px;
}

.info-section .issue-info-item .item-body .changes-list .date {
  display: block;
  margin: 0 0 5px;
}

.info-section .issue-info-item .item-body .changes-list p {
  margin: 0;
}

.info-section .issue-info-item .item-body .changes-list a {
  color: #599ac6;
}

.info-section .issue-info-item .item-section.closed h3:before {
  background: url("../images/ico-plus.png") no-repeat 0 50%;
}

.info-section .issue-info-item .item-section.closed > .item-body {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.info-section .issue-info-item .browser-info .item-body .details-list .item-label {
  width: auto;
}

.info-section .issue-info-item .browser-info .item-body .details-list .item-description {
  overflow: hidden;
}

.info-section .item-body-full {
  width: 100%;
  padding: 10px 25px 15px;
}

.info-section .item-body-left,
.info-section .item-body-right {
  width: 49%;
  display: inline-block;
  padding: 10px 0;
  vertical-align: top;
}

.info-section .item-body-left {
  padding-left: 25px;
}

.info-section .t-cell {
  padding: 5px 0;
}

.info-section .t-cell:nth-child(2) {
  padding-left: 10px;
  text-align: left;
}

.info-section .t-cell div {
  margin-left: 0;
}

.info-section .timing span {
  color: #599ac6;
}

.info-section .timing .days:after {
  content: 'd';
}

.info-section .timing .hours:after {
  content: 'h';
}

.info-section .timing .minutes:after {
  content: 'm';
}

.info-section .timing .days:after,
.info-section .timing .hours:after,
.info-section .timing .minutes:after {
  margin: 0 10px 0 3px;
  color: #6c6f6c;
}

.info-section .activity-filter {
  padding: 0 0 20px 15px;
}

.info-section .activity-filter a:not(:last-child):after {
  content: '/';
  display: inline-block;
  margin: 0 15px;
  color: #6c6f6c;
}

.info-section .activity-filter a.active {
  color: #599ac6;
}

.info-section .event .date,
.info-section .event .change-list {
  margin-bottom: 20px;
}

.info-section .event .change-list a {
  margin-right: 5px;
  color: #599ac6;
  text-decoration: underline;
}

.button-mobile-menu {
  display: none;
  border-bottom: 2px solid #fff;
  margin-top: 25px;
  vertical-align: middle;
  width: 20px;
  height: 0px;
  position: relative;
  transition: all .6s;
}

.button-mobile-menu:before {
  content: '';
  width: 20px;
  height: 0px;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: 5px;
  transition: all .6s;
}

.button-mobile-menu:after {
  content: '';
  width: 20px;
  height: 0px;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: 10px;
  transition: all .6s;
}

.task,
.tipe-check {
  width: 15px;
  height: 15px;
  background: url("../images/accept-white.png") no-repeat 50% -2px #599ac6;
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}

.bug {
  width: 15px;
  height: 15px;
  background: #ff6262;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 8px;
}

.bug:before {
  content: '';
  display: block;
  position: absolute;
  background: #fff;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -3px;
  margin-top: -4px;
}

.task-priority {
  display: inline-block;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 10px;
}

.high-severity {
  background: #ff6262;
}

.middle-severity {
  background: orange;
}

.low-severity {
  background: #60d486;
}

.highest-priority {
  background: #ff0000;
}

.high-priority {
  background: #ff6262;
}

.medium-priority {
  background: #fbd832;
}

.low-priority {
  background: #60d486;
}

.lowest-priority {
  background: #3e9a5c;
}

.task-status,
.task-state {
  border-radius: 3px;
  text-align: center;
  min-width: 80px;
  display: inline-block;
}

.task-status.in-process,
.task-state.in-work {
  background: #fbd832;
}

.task-status.to-do,
.task-state.opened,
.task-state.reopened {
  background: #599ac6;
  color: #fff;
}

.task-status.done,
.task-state.decided,
.task-state.closed {
  background: #3e9a5c;
  color: #fff;
}

button.interface,
button.edit,
button.export,
button.more {
  width: 29px;
  height: 29px;
  display: inline-block;
}

button.edit {
  background: url("../images/edit.png") no-repeat 50%;
}

button.interface {
  background: url("../images/interface.png") no-repeat 50%;
}

button.export {
  background: url("../images/export.png") no-repeat 50%;
}

button.more {
  background: url("../images/more.png") no-repeat 50%;
}

.wather-counter a {
  text-decoration: underline;
}

.wather-counter a:first-child {
  padding: 0 8px 0 0;
  color: #599ac6;
}

.wather-counter a:hover {
  text-decoration: none;
}

.copy {
  min-width: 130px;
  text-align: center;
}

.copy:before {
  display: inline-block;
  margin: 0 5px 0 0;
  content: '';
  background-image: url(../images/sprite.png);
  background-position: -40px 0px;
  width: 14px;
  height: 17px;
  vertical-align: middle;
}

.add-delete {
  display: inline-block;
  background: url(../images/actions-separator.png) 50% 50% no-repeat #fff;
}

.add-delete a {
  min-width: 130px;
  text-align: center;
}

.add-delete a:before {
  display: inline-block;
  margin: 0 5px 0 0;
  content: '';
}

.add-delete .new-inclident:before {
  background-image: url(../images/sprite.png);
  background-position: -54px 0px;
  width: 11px;
  height: 11px;
}

.add-delete .delete:before {
  background-image: url(../images/sprite.png);
  background-position: -25px -37px;
  width: 11px;
  height: 11px;
}

.close-btn {
  background-image: url(../images/sprite.png);
  background-position: 0px -37px;
  width: 15px;
  height: 15px;
  text-indent: -9999px;
  overflow: hidden;
}

.breadcrumbs {
  padding: 0 0 0 25px;
  color: #6c6f6c;
}

.breadcrumbs a {
  color: #6c6f6c;
  padding: 0 10px 0 0;
}

.breadcrumbs a:not(:last-child):after {
  content: '-';
  display: inline-block;
  padding-left: 10px;
}

.breadcrumbs a.active {
  text-decoration: underline;
}

/* Task-list */
.task-manage.table {
  display: table;
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  background: #fff;
}

.task-manage.table .t-row {
  display: table-row;
}

.task-manage.table .t-row.current {
  background: #ffe0e0;
}

.task-manage.table .t-row .t-cell {
  display: table-cell;
  border: 1px solid #c7c5c5;
  height: 30px;
  vertical-align: middle;
}

.task-manage.table .t-row .position {
  width: 2.7%;
}

.task-manage.table .t-row .tipe {
  width: 3.6%;
}

.task-manage.table .t-row .summary {
  width: 56.3%;
}

.task-manage.table .t-row .performer {
  width: 12.2%;
}

.task-manage.table .t-row .severity {
  width: 5.4%;
}

.task-manage.table .t-row .severity div {
  display: inline-block;
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.task-manage.table .t-row .priority {
  text-align: left;
  padding: 0 5px;
  width: 9%;
}

.task-manage.table .t-row .priority div {
  display: inline-block;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 10px;
}

.task-manage.table .t-row .status {
  width: 9.2%;
}

.task-manage.table .t-row .status span {
  color: #fff;
}

.task-manage.table .table-head .priority {
  text-align: center;
}

/* Task-list */
/* info-section */
/* End issue-info */
/* Element page */
.left {
  width: 645px;
  float: left;
}

.create-request {
  margin-bottom: 40px;
}

.create-request, .help {
  background: #fff;
  box-shadow: 4px 4px 0 0 #b8c7d3;
  border-radius: 5px;
  padding: 0 35px 20px;
}

.create-request:after, .help:after {
  content: '';
  display: block;
  clear: both;
}

.create-request .main-header, .help .main-header {
  padding: 20px 25px;
  margin: 0 0 20px;
  border-bottom: 1px solid #8b8d8c;
  overflow: hidden;
}

.create-request .main-header h2, .help .main-header h2 {
  color: #599ac6;
  line-height: 1;
  font-size: 24px;
  margin: 0;
}

.create-request .main-header .tools, .help .main-header .tools {
  float: right;
  background-color: #efecec;
}

.create-request .main-header .close-btn, .help .main-header .close-btn {
  float: right;
  margin: 7px 0 0 20px;
}

.request-form {
  display: table;
  border-collapse: inherit;
}

.request-form label {
  color: #599ac6;
  margin-bottom: 15px;
}

.request-form label .t-cell {
  padding: 5px 7px 5px 0;
}

.request-form .hr {
  height: 40px;
  background: url("../images/t-head-border.png") repeat-x 50%;
  background: #000;
}

.request-form .cell-left {
  min-width: 113px;
  padding-left: 25px;
  vertical-align: top;
  padding-top: 12px;
}

.request-form .cell-right {
  padding: 5px 0;
  width: 730px;
}

.request-form input[type="text"] {
  border-radius: 0px;
  border: 1px solid #8b8d8c;
  width: 260px;
}

.request-form input[name="summary"] {
  width: 100%;
  text-align: left;
}

.request-form textarea {
  border-radius: 0px;
  border: 1px solid #8b8d8c;
  padding: 6px 10px;
  width: 100%;
}

.request-form .textarea-field {
  padding-top: 30px;
}

.right {
  float: right;
  width: 970px;
}

.right .main-header {
  background: transparent;
}

.right:after {
  content: '';
  display: block;
  clear: both;
}

.btn-create {
  background: #5b9bc7;
  padding: 10px 45px;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  float: right;
}

.btn-create:active {
  box-shadow: 0 0 4px 2px #477696 inset;
}

.btn-cancel {
  color: #5b9bc7;
  padding: 10px;
  cursor: pointer;
  float: right;
}

.btn-cancel:hover {
  text-decoration: underline;
}

.buttons-container {
  padding: 40px 30px 0 0;
  display: block;
  float: right;
}

.help .table {
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

.help .table:not(:last-child) {
  margin-bottom: 25px;
}

.help .statuses .cell-left, .help .states .cell-left {
  text-align: center;
}

.help .help-wrapper {
  padding: 0 25px 0 25px;
}

.help .t-cell {
  border: 1px solid #8b8d8c;
  padding: 5px;
  vertical-align: middle;
}

.help .cell-left.t-cell {
  width: 145px;
}

.help .resolutions .cell-left {
  text-align: center;
}

/* Element page */
.elem-page-link {
  display: block;
  width: 200px;
  padding: 10px 50px;
  border: 1px solid #86d0ff;
  background: #5b9bc7;
  margin: 70px auto 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  clear: both;
}

/* login popup */
.login-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 554px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #9fc5df;
  box-shadow: 3px 3px 0 #599ac6;
  padding: 50px 48px;
}

.login-popup .select-field {
  margin: 0 0 30px;
}

.login-popup .select-field select {
  width: 100%;
  line-height: 40px;
  height: 40px;
  font-size: 15px;
}

.login-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.controls {
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
}

.controls li {
  display: inline-block;
  margin: 0 7px 0 0;
  vertical-align: middle;
}

.controls [class^="icon-"], .controls [class*=" icon-"] {
  font-size: 18px;
  background: #fff;
}

@media only screen and (max-width: 1610px) {
  .tasks {
    float: none;
    margin: 0 auto;
    width: 85%;
    max-width: 1500px;
    margin-bottom: 25px;
  }
  .tasks .priority {
    min-width: 82px;
  }
  .issue-info {
    float: none;
    margin: 0 auto;
    width: 85%;
    max-width: 1500px;
  }
}

@media only screen and (max-width: 980px) {
  .tasks,
  .issue-info {
    width: 80%;
  }
  .task-manage.table {
    display: block;
    overflow-y: scroll;
    width: 100%;
    padding-left: 40px;
    border: 1px solid #c7c5c5;
  }
  .task-manage.table .t-row {
    display: block;
    width: 912px;
    margin-top: -1px;
  }
  .task-manage.table .t-row:last-child .t-cell {
    border-bottom: none;
  }
  .task-manage.table .t-row .position {
    position: absolute;
    min-width: 40px;
    margin-left: -40px;
    margin-top: 0px;
    background: #fff;
    border-bottom: none;
    border-left: none;
  }
  .task-manage.table .t-row .t-cell:nth-child(2) {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 820px) {
  .main-header .container nav.mobile-menu {
    display: none;
    position: absolute;
    top: 60px;
    background: #599ac6;
    padding: 10px 15px;
    z-index: 9999;
    width: 200px;
  }
  .main-header .container nav.mobile-menu a {
    margin: 0;
    display: block;
    text-align: center;
    padding: 10px 0;
  }
  .main-header .container nav.mobile-menu a:not(:last-child) {
    border-bottom: 1px solid #85b5d6;
  }
  .main-header .container .button-mobile-menu {
    display: block;
    float: left;
  }
  .main-header .container .button-mobile-menu.opened {
    width: 0px;
    transition: width .6s;
  }
  .main-header .container .button-mobile-menu.opened:before {
    top: 5px;
    transform: rotate(45deg);
    transition: all .6s;
  }
  .main-header .container .button-mobile-menu.opened:after {
    top: 5px;
    transform: rotate(-225deg);
    transition: all .6s;
  }
  .tasks .action-bar {
    margin: 10px 0 15px 0px;
  }
  .tasks .action-bar .add-delete {
    width: 263px;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .main-header .container .admin-box {
    display: block;
    position: absolute;
    top: -165px;
    right: 20px;
    background: #599ac6;
    padding: 5px 10px;
    z-index: 9999;
    border-radius: 4px;
    box-shadow: 0 0 3px 3px #ccc;
    opacity: 0;
    transition: opacity .5s ease, top .6s ease .2s;
  }
  .main-header .container .admin-box.opened {
    top: 65px;
    opacity: 1;
    transition: top .4s ease, opacity .4s ease .2s;
  }
  .main-header .container .admin-box:before {
    content: '';
    display: block;
    position: absolute;
    border: 7px solid transparent;
    border-bottom-color: #599ac6;
    top: -13px;
    right: 85px;
  }
  .main-header .container .admin-box div:first-child a {
    padding: 0 15px 0 0;
  }
  .main-header .container .admin-box div a img {
    margin: 0 10px;
  }
  body {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 100%;
    left: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity .6s ease, bottom .6s ease .6s, left .6s ease .6s;
    z-index: 9999;
  }
  .overlay.opened {
    bottom: 0;
    left: 0;
    z-index: 9999;
    opacity: 1;
    transition: opacity .6s ease;
  }
  .tasks, .issue-info {
    width: 96%;
  }
  .admin-box-mobile-link {
    display: block;
  }
  .aside-menu {
    left: -70px;
    z-index: 9999;
    top: 30px;
  }
  .aside-menu.opened .button-aside-menu {
    transform: rotate(-225deg);
    transition: all .4s ease .3s;
    left: 10px;
    top: 10px;
  }
  .aside-menu .button-aside-menu {
    display: inline-block;
    border: 3px solid transparent;
    font-weight: bold;
    padding: 4px;
    text-align: center;
    left: 73px;
    top: 40px;
    position: absolute;
    border-bottom-color: #599ac6;
    border-right-color: #599ac6;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: rotate(-45deg);
    transition: all .4s ease .3s;
  }
}

@media only screen and (max-width: 680px) {
  .main-header .container {
    padding: 0 10px;
  }
  .main-header .container .admin-box {
    right: 14px;
  }
  .main-header .container .admin-box:before {
    right: 20px;
  }
  .issue-info .issue-info-item .item-body-left,
  .issue-info .issue-info-item .item-body-right,
  .issue-info .issue-info-item .item-body-full {
    width: 100%;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .issue-info .action-bar > * {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .tasks .action-bar .filter-form {
    margin-bottom: 15px;
  }
}
