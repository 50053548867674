* {
	outline: none;

	&:focus {outline: none;}
}

img {
	vertical-align: top;
	border: 0;
	max-width: 100%;
	height: auto;
}

header,
footer,
article,
section,
hgroup,
nav,
figure,
main{
	display: block;
}

form,
fieldset,
button {
	margin: 0;
	padding: 0;
	border: none;
}

button {
	background-color: transparent;
	-webkit-appearance: none;
    cursor: pointer;
}

input {
	box-sizing: border-box;
	border-radius: 0;
	font: 18px/30px $primary-font-family;
	font-weight: 400;
	-webkit-appearance: none;

	&[type=text],
	&[type=tel],
	&[type=email],
	&[type=password] {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 10px;
        border: 1px solid #c7c5c5;
        border-width: 0 0 1px;
        margin: 0 0 30px;
		&.error {border-bottom: 1px solid #ec1b35;}
	}

	&[type="submit"] {
        height: 30px;
        border-radius: 3px;
        border: none;
        background: #5b9bc7;
        color: #fff;
        text-transform: uppercase;
        width: 100%;
        max-width: 226px;
        font: 18px/30px $primary-font-family;
        display: block;
        margin: 0 auto;
        cursor: pointer;

		&:hover {
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
		}
	}

	@include placeholder {
		color: #6c6f6c;
		opacity: 1;
	}

	&:focus {
		@include placeholder {opacity: 0.5;}
	}
}

textarea {
	font: 30px/1.1 $primary-font-family;

	@include placeholder {
		color: #fff;
		opacity: 1;
		text-transform: uppercase;
	}

	&:focus {
		border: 1px solid #00adef;

		@include placeholder {opacity: 0.5;}
	}

	&.error {border-bottom: 1px solid #ec1b35;}
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	outline: none;
	border: none;
}

body {
	margin: 0;
	overflow-x: hidden;
	font: 14px/1.4 $primary-font-family;
	color: #6c6f6c;
	text-size-adjust: 100%;
	background: #efecec;
}

a {
    text-decoration: none;
    color: #6c6f6c;

    &:hover {
        text-decoration: underline;
    }
}

h2 {
    font-size: 13px;
    color: #5b9bc7;
}
.small-btn{
    width: 30px;
    height: 30px;
    border-radius: 3px;
    box-shadow: 1px 1px 0 $blue;
    background: $light-gray;
    display: inline-block;
    text-indent: -9999px;
    position: relative;

    &.chat{
        background: $light-gray url("../images/interface.png") no-repeat 50% 50%;
    }

    &.edit{
        background: $light-gray url("../images/edit.png") no-repeat 50% 50%;
    }

    &:hover{
        top: -2px;
    }

    &[class^="icon-"]:before, &[class*=" icon-"]:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        text-indent: 0;
    }
}
#main{
    padding: 0 0 0 75px;

    &:after{
        content: "";
        display: block;
        clear: both;
    }
}

#header {
    background: #599ac6;
    color: #fff;
    font-size: 18px;
    padding: 0 20px;
    height: 60px;

    .container {
        padding: 0 10px;
    }
    .admin-box {
        float: right;
        padding: 3px 0 0;
        div {
            display: inline-block;

            a {
                display: inline-block;
                color: #fff;
                img {
                    display: inline-block;
                    margin: 0 20px;
                    vertical-align: middle;
                }
            }
            &:first-child{
                a{
                    padding: 0 35px 0 0;
                }
            }
            &:first-child:after {
                content: '';
                @include sprite ($admin-separatop);
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
.logo {
    float: left;
    margin: 10px 40px 0 0;

    img {
        width: 51px;
    }
}
#main-navigation{
    ul{
        margin: 20px 0 10px;
        padding: 0;
        list-style: none;
        display: inline-block;
        line-height: 1.2;

        li{
            float: left;
            margin: 0 25px 0 0;
        }
    }
    a {
        color: #fff;
        &:not(:last-child) {
            margin: 0 25px 0 0;
        }
    }
}
.aside-menu {
    position: fixed;
    top: 61px;
    left: 0;

    ul {
        padding: 27px 19px 20px;
        background: #fff;
        border: 1px solid #c7c5c5;
        margin: 0;

        li {
            display: block;
            padding: 15px 0 15px;
            background: url("../images/aside-separator.png") no-repeat 50% 0;

            &:first-child{
                padding-top: 0;
                background: none;
            }

            &:last-child {
                margin-top: 115px;
                padding: 0;
                background: none;
            }

            a{
                display: block;
                margin: 0 auto;
                text-indent: -9999px;
            }

            .new-file{
                background: url("../images/new-file.png") no-repeat;
                width: 29px;
                height: 38px;
            }

            .list{
                background: url("../images/list.png") no-repeat;
                width: 29px;
                height: 40px;
            }

            .chat{
                background: url("../images/chat.png") no-repeat;
                width: 29px;
                height: 24px;
            }

            .statistic{
                background: url("../images/bar-chart.png") no-repeat;
                width: 30px;
                height: 26px;
            }

            .settings{
                background: url("../images/settings.png") no-repeat;
                width: 28px;
                height: 28px;
            }
        }
    }
}
.admin-box-mobile-link {
    display: none;
    float: right;
}
.work-section {
    float: left;
    padding-top: 12px;
    width: 58.86%;

    .action-bar{
        margin: 0 0 14px;

        &:after{
            content: "";
            display: block;
            clear: both;
        }

        .small-btn{
            margin: 0 0 0 10px;
        }
        .refresh {
            float: left;
            margin: 0 7px 0 0;

            &:before {
                content: '';
                display: block;
                width: 30px;
                height: 30px;
                background: url("../images/refresh.png") 50% 50% no-repeat;
                position: relative;
                top: 0;
                left: 0 ;
                transform: rotate(0deg);
                transition: transform 3s ;
            }
            &:hover {
                &:before{
                    transform: rotate(360deg);
                    transition: transform 3s ;
                }
            }
        }

        .settings {
            float: right;
            margin: 0 7px;

            &:before {
                content: '';
                display: block;
                width: 30px;
                height: 30px;
                background: url("../images/tools.png") 50% 50% no-repeat;
                position: absolute;
                transform: rotate(0deg);
                transition: transform 3s;
                top: 0px;
                left: 0px;
            }
            &:hover {
                &:before {
                    transform: rotate(360deg);
                    transition: transform 3s;
                }
            }
        }
        .filter-option{
            display: none;
        }
    }

    table{
        width: 100%;
        background: #fff;
        border: 1px solid #c7c5c5;
        border-collapse: collapse;

        th{
            border: 1px solid #c7c5c5;
            height: 30px;
            background: #599ac6;
            text-align: center;
            padding: 0 10px;
            color: #fff;
        }

        td{
            border: 1px solid #c7c5c5;
            height: 30px;
            padding: 0 10px;
        }

        tr {
            &:nth-child(2n) td{
                background: #f5f5f5;
            }

            &:hover td{
                background: #eff3fc;
            }

            &.active td{
                background: #bfcef5;
            }
        }

        .field-id{
            text-align: center;
        }
        .status{
            text-align: center;
        }
    }
}

span{
    &.processed{
        background: #3e9a5c;
        color: #fff;
        padding: 3px 5px 1px;
        vertical-align: middle;
        border-radius: 3px;
        font-size: 12px;
        display: inline-block;
    }

    &.not-processed{
        background: #ff6262;
        color: #fff;
        padding: 3px 5px 1px;
        vertical-align: middle;
        border-radius: 3px;
        font-size: 12px;
        display: inline-block;
    }

    &.active{
        background: $blue;
        color: #fff;
        padding: 3px 5px 1px;
        vertical-align: middle;
        border-radius: 3px;
        font-size: 12px;
        display: inline-block;
    }
}

.select-field{
    position: relative;

    &:after{
        content: "";
        position: absolute;
        background: url("../images/drop-down.png") no-repeat;
        width: 11px;
        height: 6px;
        right: 9px;
        top: 50%;
        margin: -3px 0 0;
    }

    select{
        display: block;
        background: $light-gray;
        width: 131px;
        height: 30px;
        border-radius: 4px;
        border: none;
        -webkit-appearance: none;
        padding: 0 10px;
        font-size: 13px;
        color: #6c6f6c;
        text-align: center;
        line-height: 30px;
        box-shadow: 1px 1px 0 #599ac6;
    }
}
.info-section {
    float: right;
    width: 40.92%;
    border: 2px solid #c7c5c5;
    padding: 10px;
    background: #fff;
    margin-right: 2px;
    box-sizing: border-box;

    &:after {
        content: '';
        display: block;
        clear: both;
    }
    .info-section-headline {
        padding: 10px 0;

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        h2{
            overflow: hidden;
            font-size: 18px;
            color: $gray;
            margin: 0;
            line-height: 30px;
        }
    }
    .action-bar{
        float: right;

        .select-field{
            float: left;
        }
        .small-btn{
            margin: 0 0 0 10px;
        }
    }

    .issue-info-item {
        margin-bottom: 10px;

        .item-header {
            background: linear-gradient(to right, #e7e9e8, 12%, #fff);
            cursor: pointer;
            font-size: 13px;
            line-height: 30px;
            padding: 0 10px;

            h3 {
                margin: 0 0 15px;

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin: -2px 7px 0 0;
                    width: 11px;
                    height: 11px;
                    background: url("../images/ico-minus.png") no-repeat 0 50%;
                }
            }
        }

        .item-body {
            padding: 0 27px 5px;
            font-size: 13px;
            transition: height 0.5s linear;

            .details-list{
                margin: 0;
                padding: 0;
                list-style: none;

                li{
                    overflow: hidden;
                    margin: 0 0 15px;
                    border-bottom: 1px solid #e1e1e1;
                }

                .item-label{
                    float: left;
                    width: 115px;
                    margin: 0 10px 0 0;
                }

                .item-description{
                    overflow: hidden;

                    p{
                        margin: 0 0 15px;
                    }

                    .edit:before,
                    .save:before,
                    .cancel:before{
                        font-family: 'icomoon' !important;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        text-indent: 0;
                        color: #599ac6;
                        vertical-align: middle;
                        margin: 0 4px 0 0;
                    }

                    .edit{
                        width: 15px;
                        height: 15px;
                        background-size: cover;
                        box-shadow: none;
                        top: 0 !important;
                        background: none;
                        color: #599ac6;
                        vertical-align: middle;
                        margin: 0 0 0 5px;

                         &:before{
                             content: "\e906";
                             position: absolute;
                             left: 0;
                             top: 0;
                         }
                    }

                    .save{
                        margin: 0 10px 0 0;
                        font-size: 12px;
                        &:before{
                            content: "\ea10";
                            font-size: 13px;
                        }
                    }
                    .cancel{
                        font-size: 12px;
                        &:before{
                            content: "\ea0f";
                            font-size: 11px;
                        }
                    }
                    input{
                        margin: 0 0 10px;
                    }
                }
            }

            .two-columns{
                column-count: 2;
                column-gap: 50px;
                padding: 0 0 10px;
            }

            ol{
                margin: 0 0 10px 17px;
                padding: 0;

                li{
                    margin: 0 0 5px;
                }
            }

            .changes-list{
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 0 0 15px;
                }

                .date{
                    display: block;
                    margin: 0 0 5px;
                }

                p{
                    margin: 0;
                }

                a{color: $blue;}
            }
        }

        .item-section.closed{
            h3 {

                &:before {
                    background: url("../images/ico-plus.png") no-repeat 0 50%;
                }
            }

            & > .item-body{
                height: 0;
                padding: 0;
                overflow: hidden;
            }
        }
        .browser-info {

            .item-body {

                .details-list{
                    .item-label{
                        width: auto;
                    }

                    .item-description{
                        overflow: hidden;

                    }
                }

            }

        }

    }

}






















.info-section{

    .item-body-full {
        width: 100%;
        padding: 10px 25px 15px;
    }
    .item-body-left,
    .item-body-right {
        width: 49%;
        display: inline-block;
        padding: 10px 0;
        vertical-align: top;
    }
    .item-body-left {
        padding-left: 25px;
    }
    .t-cell {
        padding: 5px 0;
        &:nth-child(2) {
            padding-left: 10px;
            text-align: left;
        }
        div {
            margin-left: 0;
        }
    }
    .timing {
        span {
            color: #599ac6;
        }
        .days {
            &:after {
                content: 'd'
            }
        }
        .hours {
            &:after {
                content: 'h'
            }
        }
        .minutes {
            &:after {
                content: 'm'
            }
        }
        .days,
        .hours,
        .minutes {
            &:after{
                margin: 0 10px 0 3px;
                color: #6c6f6c;
            }
        }
    }
    .activity-filter {
        padding: 0 0 20px 15px;
        a {
            &:not(:last-child):after {
                content: '/';
                display: inline-block;
                margin: 0 15px;
                color: #6c6f6c;
            }
            &.active {
                color: #599ac6;
            }
        }
    }
    .event {
        .date,
        .change-list {
            margin-bottom: 20px;
        }
        .change-list {
            a {
                margin-right: 5px;
                color: #599ac6;
                text-decoration: underline;
            }
        }
    }

}



.button-mobile-menu {
    display: none;
    border-bottom: 2px solid #fff;
    margin-top: 25px;
    vertical-align: middle;
    width: 20px;
    height: 0px;
    position: relative;
    transition: all .6s;
    &:before {
        content: '';
        width: 20px;
        height: 0px;
        border-bottom: 2px solid #fff;
        position: absolute;
        top: 5px;
        transition: all .6s;
    }
    &:after {
        content: '';
        width: 20px;
        height: 0px;
        border-bottom: 2px solid #fff;
        position: absolute;
        top: 10px;
        transition: all .6s;
    }
}
.task,
.tipe-check {
    width: 15px;
    height: 15px;
    background: url('../images/accept-white.png') no-repeat 50% -2px #599ac6;
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px;
}
.bug {
    width: 15px;
    height: 15px;
    background: #ff6262;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0 8px;
    &:before {
        content: '';
        display: block;
        position: absolute;
        background: #fff;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin-left: -3px;
        margin-top: -4px;
    }
}
.task-priority {
    display: inline-block;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin: 0 10px;
}
.high-severity {
    background: #ff6262;
}
.middle-severity {
    background: orange;
}
.low-severity {
    background: #60d486;
}
.highest-priority {
    background: #ff0000;
}
.high-priority {
    background: #ff6262;
}

.medium-priority {
    background: #fbd832;
}
.low-priority {
    background: #60d486;
}
.lowest-priority {
    background: #3e9a5c;
}
.task-status,
.task-state {
    border-radius: 3px;
    text-align: center;
    min-width: 80px;
    display: inline-block;
}
.task-status.in-process,
.task-state.in-work {
    background: #fbd832;
}
.task-status.to-do,
.task-state.opened,
.task-state.reopened {
    background: #599ac6;
    color: #fff;
}
.task-status.done,
.task-state.decided,
.task-state.closed {
    background: #3e9a5c;
    color: #fff;
}
button.interface,
button.edit,
button.export,
button.more{
    width: 29px;
    height: 29px;
    display: inline-block;
}
button.edit {
    background: url('../images/edit.png') no-repeat 50%;
}
button.interface {
    background: url('../images/interface.png') no-repeat 50%;
}
button.export {
    background: url('../images/export.png') no-repeat 50%;
}
button.more {
    background: url('../images/more.png') no-repeat 50%;
}
.wather-counter {
    a {
       text-decoration: underline;
        &:first-child {
            padding: 0 8px 0 0;
            color: #599ac6;
        }
        &:hover {
            text-decoration: none;
        }
    }
}
.copy {
    min-width: 130px;
    text-align: center;
    &:before {
        display: inline-block;
        margin: 0 5px 0 0;
        content: '';
        @include sprite ($copy);
        vertical-align: middle;
    }
}
.add-delete {
    display: inline-block;
    background: url(../images/actions-separator.png) 50% 50% no-repeat #fff;
    a {
        min-width: 130px;
        text-align: center;
        &:before {
            display: inline-block;
            margin: 0 5px 0 0;
            content: '';
        }
    }
    .new-inclident {
        &:before {
            @include sprite ($new-incident);
        }
    }
    .delete {
        &:before {
            @include sprite ($delete);
        }
    }
}

.close-btn {
    @include sprite ($close);
    text-indent: -9999px;
    overflow: hidden;
}
.breadcrumbs {
    padding: 0 0 0 25px;
    color: #6c6f6c;
    a {
        color: #6c6f6c;
        padding: 0 10px 0 0;
        &:not(:last-child):after {
            content: '-';
            display: inline-block;
            padding-left: 10px;
        }
        &.active {
            text-decoration: underline;
        }
    }
}


/* Task-list */

.task-manage.table {
    display: table;
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    .t-row {
        display: table-row;
        &.current {
            background: #ffe0e0;
        }
        .t-cell {
            display: table-cell;
            border: 1px solid #c7c5c5;
            height: 30px;
            vertical-align: middle;
        }
        .position {
            width: 2.7%;
        }
        .tipe {
            width: 3.6%;
        }
        .purple {

        }
        .summary {
            width: 56.3%;
        }
        .performer {
            width: 12.2%;
        }
        .severity {
            width: 5.4%;

            div {
                display: inline-block;
                border-radius: 50%;
                width: 9px;
                height: 9px;
            }
        }
        .priority {
            text-align: left;
            padding: 0 5px;
            width: 9%;
            div {
                display: inline-block;
                border-radius: 50%;
                width: 9px;
                height: 9px;
                margin: 0 10px;
            }
        }
        .status {
            width: 9.2%;
            span {
                color: #fff;
            }
        }
    }
    .table-head {
        .priority {
            text-align: center;
        }
    }
}
/* Task-list */


/* info-section */



/* End issue-info */


/* Element page */
.left {
    width: 645px;
    float: left;
}
.create-request {
    margin-bottom: 40px;
}
.create-request, .help {
    background: #fff;
    box-shadow: 4px 4px 0 0 #b8c7d3;
    border-radius: 5px;
    padding: 0 35px 20px;
    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .main-header{
        padding: 20px 25px ;
        margin: 0 0 20px;
        border-bottom: 1px solid #8b8d8c;
        overflow: hidden;

        h2 {
            color: #599ac6;
            line-height: 1;
            font-size: 24px;
            margin: 0;
        }
        .tools {
            float: right;
            background-color: #efecec;
        }
        .close-btn {
            float: right;
            margin: 7px 0 0 20px;
        }
    }
}

.request-form {
    display: table;
    border-collapse: inherit;


    label {
        color: #599ac6;
        margin-bottom: 15px;
        .t-cell {
            padding: 5px 7px 5px 0;
        }
    }
    .hr {
        height: 40px;
        background: url('../images/t-head-border.png') repeat-x 50%;
        background: #000;
    }
    .cell-left {
        min-width: 113px;
        padding-left: 25px;
        vertical-align: top;
        padding-top: 12px;
    }
    .cell-right {
        padding: 5px 0;
        width: 730px;
    }

    input[type="text"]{
        border-radius: 0px;
        border: 1px solid #8b8d8c;
        width: 260px;
    }
    input[name="summary"] {
        width: 100%;
        text-align: left;
    }
    textarea {
        border-radius: 0px;
        border: 1px solid #8b8d8c;
        padding: 6px 10px;
        width: 100%;
    }
    .textarea-field {
        padding-top: 30px;
    }
}


.right {
    .main-header {
        background: transparent;
    }
    float: right;
    width: 970px;
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}
.btn-create {
    background: #5b9bc7;
    padding: 10px 45px;
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    float: right;
    &:active {
        box-shadow: 0 0 4px 2px #477696 inset;
    }
}
.btn-cancel {
    color: #5b9bc7;
    padding: 10px;
    cursor: pointer;
    float: right;

    &:hover {
        text-decoration: underline;
    }
}
.buttons-container {
    padding: 40px 30px 0 0;
    display: block;
    float: right;
}

.help {
    .table {
        border-collapse: collapse;
        width: 100%;
        font-size: 13px;
        &:not(:last-child) {
            margin-bottom: 25px;
        }

    }
    .statuses, .states {
        .cell-left {
            text-align: center;
        }
    }
    .help-wrapper {
        padding: 0 25px 0 25px;
    }
    .t-cell {
        border: 1px solid #8b8d8c;
        padding: 5px;
        vertical-align: middle;
    }
    .cell-left.t-cell {
        width: 145px;
    }
    .resolutions {
        .cell-left {
            text-align: center;
        }
    }
}
/* Element page */
.elem-page-link {
    display: block;
    width: 200px;
    padding: 10px 50px;
    border: 1px solid #86d0ff;
    background: #5b9bc7;
    margin: 70px auto 20px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    clear: both;
}
/* login popup */
.login-popup{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 554px;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #9fc5df;
    box-shadow: 3px 3px 0 #599ac6;
    padding: 50px 48px;

    .select-field {
        margin: 0 0 30px;
        select {
            width: 100%;
            line-height: 40px;
            height: 40px;
            font-size: 15px;
        }
    }
}
.login-popup .close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
}
.controls{
    margin: 0 0 5px;
    padding: 0;
    list-style: none;

    li{
        display: inline-block;
        margin: 0 7px 0 0;
        vertical-align: middle;
    }

    [class^="icon-"], [class*=" icon-"] {
        font-size: 18px;
        background: #fff;
    }
}