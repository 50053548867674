// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$accept-grey-name: 'accept-grey';
$accept-grey-x: 54px;
$accept-grey-y: 11px;
$accept-grey-offset-x: -54px;
$accept-grey-offset-y: -11px;
$accept-grey-width: 10px;
$accept-grey-height: 8px;
$accept-grey-total-width: 67px;
$accept-grey-total-height: 57px;
$accept-grey-image: '../images/sprite.png';
$accept-grey: (54px, 11px, -54px, -11px, 10px, 8px, 67px, 57px, '../images/sprite.png', 'accept-grey', );
$accept-white-name: 'accept-white';
$accept-white-x: 15px;
$accept-white-y: 37px;
$accept-white-offset-x: -15px;
$accept-white-offset-y: -37px;
$accept-white-width: 10px;
$accept-white-height: 13px;
$accept-white-total-width: 67px;
$accept-white-total-height: 57px;
$accept-white-image: '../images/sprite.png';
$accept-white: (15px, 37px, -15px, -37px, 10px, 13px, 67px, 57px, '../images/sprite.png', 'accept-white', );
$actions-separator-name: 'actions-separator';
$actions-separator-x: 66px;
$actions-separator-y: 0px;
$actions-separator-offset-x: -66px;
$actions-separator-offset-y: 0px;
$actions-separator-width: 1px;
$actions-separator-height: 30px;
$actions-separator-total-width: 67px;
$actions-separator-total-height: 57px;
$actions-separator-image: '../images/sprite.png';
$actions-separator: (66px, 0px, -66px, 0px, 1px, 30px, 67px, 57px, '../images/sprite.png', 'actions-separator', );
$admin-separatop-name: 'admin-separatop';
$admin-separatop-x: 65px;
$admin-separatop-y: 0px;
$admin-separatop-offset-x: -65px;
$admin-separatop-offset-y: 0px;
$admin-separatop-width: 1px;
$admin-separatop-height: 30px;
$admin-separatop-total-width: 67px;
$admin-separatop-total-height: 57px;
$admin-separatop-image: '../images/sprite.png';
$admin-separatop: (65px, 0px, -65px, 0px, 1px, 30px, 67px, 57px, '../images/sprite.png', 'admin-separatop', );
$aside-separator-name: 'aside-separator';
$aside-separator-x: 15px;
$aside-separator-y: 50px;
$aside-separator-offset-x: -15px;
$aside-separator-offset-y: -50px;
$aside-separator-width: 30px;
$aside-separator-height: 1px;
$aside-separator-total-width: 67px;
$aside-separator-total-height: 57px;
$aside-separator-image: '../images/sprite.png';
$aside-separator: (15px, 50px, -15px, -50px, 30px, 1px, 67px, 57px, '../images/sprite.png', 'aside-separator', );
$close-name: 'close';
$close-x: 0px;
$close-y: 37px;
$close-offset-x: 0px;
$close-offset-y: -37px;
$close-width: 15px;
$close-height: 15px;
$close-total-width: 67px;
$close-total-height: 57px;
$close-image: '../images/sprite.png';
$close: (0px, 37px, 0px, -37px, 15px, 15px, 67px, 57px, '../images/sprite.png', 'close', );
$copy-name: 'copy';
$copy-x: 40px;
$copy-y: 0px;
$copy-offset-x: -40px;
$copy-offset-y: 0px;
$copy-width: 14px;
$copy-height: 17px;
$copy-total-width: 67px;
$copy-total-height: 57px;
$copy-image: '../images/sprite.png';
$copy: (40px, 0px, -40px, 0px, 14px, 17px, 67px, 57px, '../images/sprite.png', 'copy', );
$delete-name: 'delete';
$delete-x: 25px;
$delete-y: 37px;
$delete-offset-x: -25px;
$delete-offset-y: -37px;
$delete-width: 11px;
$delete-height: 11px;
$delete-total-width: 67px;
$delete-total-height: 57px;
$delete-image: '../images/sprite.png';
$delete: (25px, 37px, -25px, -37px, 11px, 11px, 67px, 57px, '../images/sprite.png', 'delete', );
$drop-down-name: 'drop-down';
$drop-down-x: 54px;
$drop-down-y: 19px;
$drop-down-offset-x: -54px;
$drop-down-offset-y: -19px;
$drop-down-width: 11px;
$drop-down-height: 6px;
$drop-down-total-width: 67px;
$drop-down-total-height: 57px;
$drop-down-image: '../images/sprite.png';
$drop-down: (54px, 19px, -54px, -19px, 11px, 6px, 67px, 57px, '../images/sprite.png', 'drop-down', );
$edit-name: 'edit';
$edit-x: 18px;
$edit-y: 19px;
$edit-offset-x: -18px;
$edit-offset-y: -19px;
$edit-width: 18px;
$edit-height: 18px;
$edit-total-width: 67px;
$edit-total-height: 57px;
$edit-image: '../images/sprite.png';
$edit: (18px, 19px, -18px, -19px, 18px, 18px, 67px, 57px, '../images/sprite.png', 'edit', );
$export-name: 'export';
$export-x: 21px;
$export-y: 0px;
$export-offset-x: -21px;
$export-offset-y: 0px;
$export-width: 19px;
$export-height: 19px;
$export-total-width: 67px;
$export-total-height: 57px;
$export-image: '../images/sprite.png';
$export: (21px, 0px, -21px, 0px, 19px, 19px, 67px, 57px, '../images/sprite.png', 'export', );
$interface-name: 'interface';
$interface-x: 0px;
$interface-y: 0px;
$interface-offset-x: 0px;
$interface-offset-y: 0px;
$interface-width: 21px;
$interface-height: 19px;
$interface-total-width: 67px;
$interface-total-height: 57px;
$interface-image: '../images/sprite.png';
$interface: (0px, 0px, 0px, 0px, 21px, 19px, 67px, 57px, '../images/sprite.png', 'interface', );
$issue-info-item-close-name: 'issue-info-item-close';
$issue-info-item-close-x: 54px;
$issue-info-item-close-y: 30px;
$issue-info-item-close-offset-x: -54px;
$issue-info-item-close-offset-y: -30px;
$issue-info-item-close-width: 9px;
$issue-info-item-close-height: 1px;
$issue-info-item-close-total-width: 67px;
$issue-info-item-close-total-height: 57px;
$issue-info-item-close-image: '../images/sprite.png';
$issue-info-item-close: (54px, 30px, -54px, -30px, 9px, 1px, 67px, 57px, '../images/sprite.png', 'issue-info-item-close', );
$issue-info-item-open-name: 'issue-info-item-open';
$issue-info-item-open-x: 36px;
$issue-info-item-open-y: 37px;
$issue-info-item-open-offset-x: -36px;
$issue-info-item-open-offset-y: -37px;
$issue-info-item-open-width: 11px;
$issue-info-item-open-height: 11px;
$issue-info-item-open-total-width: 67px;
$issue-info-item-open-total-height: 57px;
$issue-info-item-open-image: '../images/sprite.png';
$issue-info-item-open: (36px, 37px, -36px, -37px, 11px, 11px, 67px, 57px, '../images/sprite.png', 'issue-info-item-open', );
$more-name: 'more';
$more-x: 0px;
$more-y: 52px;
$more-offset-x: 0px;
$more-offset-y: -52px;
$more-width: 21px;
$more-height: 5px;
$more-total-width: 67px;
$more-total-height: 57px;
$more-image: '../images/sprite.png';
$more: (0px, 52px, 0px, -52px, 21px, 5px, 67px, 57px, '../images/sprite.png', 'more', );
$new-incident-name: 'new-incident';
$new-incident-x: 54px;
$new-incident-y: 0px;
$new-incident-offset-x: -54px;
$new-incident-offset-y: 0px;
$new-incident-width: 11px;
$new-incident-height: 11px;
$new-incident-total-width: 67px;
$new-incident-total-height: 57px;
$new-incident-image: '../images/sprite.png';
$new-incident: (54px, 0px, -54px, 0px, 11px, 11px, 67px, 57px, '../images/sprite.png', 'new-incident', );
$refresh-name: 'refresh';
$refresh-x: 40px;
$refresh-y: 17px;
$refresh-offset-x: -40px;
$refresh-offset-y: -17px;
$refresh-width: 14px;
$refresh-height: 14px;
$refresh-total-width: 67px;
$refresh-total-height: 57px;
$refresh-image: '../images/sprite.png';
$refresh: (40px, 17px, -40px, -17px, 14px, 14px, 67px, 57px, '../images/sprite.png', 'refresh', );
$select-border-large-name: 'select-border-large';
$select-border-large-x: 54px;
$select-border-large-y: 29px;
$select-border-large-offset-x: -54px;
$select-border-large-offset-y: -29px;
$select-border-large-width: 10px;
$select-border-large-height: 1px;
$select-border-large-total-width: 67px;
$select-border-large-total-height: 57px;
$select-border-large-image: '../images/sprite.png';
$select-border-large: (54px, 29px, -54px, -29px, 10px, 1px, 67px, 57px, '../images/sprite.png', 'select-border-large', );
$select-border-name: 'select-border';
$select-border-x: 54px;
$select-border-y: 31px;
$select-border-offset-x: -54px;
$select-border-offset-y: -31px;
$select-border-width: 8px;
$select-border-height: 1px;
$select-border-total-width: 67px;
$select-border-total-height: 57px;
$select-border-image: '../images/sprite.png';
$select-border: (54px, 31px, -54px, -31px, 8px, 1px, 67px, 57px, '../images/sprite.png', 'select-border', );
$t-head-border-name: 't-head-border';
$t-head-border-x: 54px;
$t-head-border-y: 25px;
$t-head-border-offset-x: -54px;
$t-head-border-offset-y: -25px;
$t-head-border-width: 9px;
$t-head-border-height: 4px;
$t-head-border-total-width: 67px;
$t-head-border-total-height: 57px;
$t-head-border-image: '../images/sprite.png';
$t-head-border: (54px, 25px, -54px, -25px, 9px, 4px, 67px, 57px, '../images/sprite.png', 't-head-border', );
$tools-name: 'tools';
$tools-x: 0px;
$tools-y: 19px;
$tools-offset-x: 0px;
$tools-offset-y: -19px;
$tools-width: 18px;
$tools-height: 18px;
$tools-total-width: 67px;
$tools-total-height: 57px;
$tools-image: '../images/sprite.png';
$tools: (0px, 19px, 0px, -19px, 18px, 18px, 67px, 57px, '../images/sprite.png', 'tools', );
$spritesheet-width: 67px;
$spritesheet-height: 57px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($accept-grey, $accept-white, $actions-separator, $admin-separatop, $aside-separator, $close, $copy, $delete, $drop-down, $edit, $export, $interface, $issue-info-item-close, $issue-info-item-open, $more, $new-incident, $refresh, $select-border-large, $select-border, $t-head-border, $tools, );
$spritesheet: (67px, 57px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
