$primary-font-family: 'Trebuchet MS', Arial, Verdana, sans-serif;
$primary-text-color: #414042;

$blue: #599ac6;
$gray: #6c6f6c;
$light-gray: #efecec;

$sky-blue: #75C2D2;
$dark-purple: #6D62AE;
$green: #92C493;
$lighter-purple: #A95589;
$yellow: #E3C25A;
$orange: #D6916A;
$radio-check-color: #599ac6;